<script>
import {setEfficiencyCoefficient} from "@/services/api/requests";

export default {
  name: "EfficiencyCoefficient",
	props: {
		year: {
			type: [Number, String],
			required: true,
		},
		month: {
      type: [Number, String],
			required: true,
		},
		department: {
			type: Object,
			required: true,
		},
	},
	data: function () {
		return {
			loading: false,
			dialog: false,
      newCoefficient: 1,
		}
	},
  computed: {
    efficiencyCoefficient: {
      get() {
        return (Number(this.department.efficiency_coef) || 1).toFixed(1)
      },
      async set(value) {
        await this.setEfficiencyCoefficient(value)
      },
    },
  },
  watch: {
    department: {
      handler() {
        this.reset()
      },
      deep: true,
    },
  },
  created() {
    this.reset()
  },
  methods: {
    reset() {
      this.newCoefficient = this.department.efficiency_coef || 1
    },
    async setEfficiencyCoefficient(value) {
      this.loading = true
      try {
        await setEfficiencyCoefficient(this.year, this.month, this.department.id, value)
        this.$emit('update')
      } catch (e) {
        this.reset()
      }
      this.loading = false
    },
    save() {
      this.dialog = false
      this.efficiencyCoefficient = this.newCoefficient
    },
    close() {
      this.dialog = false
      this.reset()
    },
  }
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="450"
    persistent
  >
    <template #activator="{on, attrs}">
      <v-chip
        v-bind="attrs"
        :loading="loading"
        v-on="on"
      >
        <strong>{{ efficiencyCoefficient }}</strong>
      </v-chip>
    </template>
    <v-card>
      <v-card-title>
        Общий групповой показатель KPI (коэффициент загрузки производства)
      </v-card-title>
      <v-card-text>
        <p>
          Коэффициент загрузки производства показывает, насколько подразделение эффективно работает.
          Чем выше коэффициент, тем эффективнее работает отдел.
          Значение коэффициента по-умолчанию:
          <v-chip @click="newCoefficient = 1">
            1.0
          </v-chip>
        </p>
        <div
          style="width: 100%"
          class="d-flex align-center justify-center"
        >
          <v-chip
            x-large
            class="px-8 mx-auto"
          >
            <strong>
              {{ newCoefficient.toFixed(1) }}
            </strong>
          </v-chip>
        </div>
        <v-slider
          v-model="newCoefficient"
          :loading="loading"
          :max="2"
          :min="0"
          :step="0.1"
          :thumb-label="false"
        />
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-0">
          <v-col>
            <v-btn
              color="primary"
              text
              block
              @click="save"
            >
              Сохранить
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              block
              depressed
              color="primary"
              @click="close"
            >
              Отменить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>