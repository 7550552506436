<script>
import DocumentViewer from "@/components/DocumentViewer.vue";
export default {
  name: "DialogDocumentViewer",
  components: {
    DocumentViewer,
  },
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    fullscreen
  >
    <template #activator="{on, attrs}">
      <v-btn
        color="primary"
        outlined
        small
        v-bind="attrs"
        v-on="on"
      >
        Просмотр
      </v-btn>
    </template>
    <v-card>
      <DocumentViewer
        :dialog="true"
        class="pa-2"
        :documents="documents"
        :min-height="0"
        @close="dialog = false"
      />
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>