<script>
import ApiDoc from "@/services/api/api-doc";

export default {
  name: "DialogDownload",
  props: {
    name: {
      type: String,
      default: "",
    },
    archive: {
      type: Boolean,
    },
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      downloadError: false,
    };
  },
  methods: {
    async getLink() {
      if (this.archive)
        return await ApiDoc.get(`application/${this.id}/documents`);
      else {
        const params = new URLSearchParams();
        params.append("documentId", this.id);
        const config = { params };
        return await ApiDoc.get("document/download", config);
      }
    },
    onCloseDialog() {
      this.dialog = false;
    },
    onDownload() {
      this.loading = true;
      setTimeout(async () => {
        try {
          const link = await this.getLink();
          link.download = this.name;
          document.body.appendChild(link);
          link.click();
          this.downloadError = false;
          setTimeout(() => {
            this.dialog = false;
            this.loading = false;
          }, 200);
        } catch {
          this.downloadError = true;
          this.loading = false;
        }
      });
    }
  }
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    width="350"
  >
    <template #activator="{on, attrs}">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      />
    </template>
    <v-card>
      <v-card-title>
        Скачать файл?
      </v-card-title>
      <v-card-subtitle>{{ name }}</v-card-subtitle>
      <v-card-text
        v-show="downloadError"
        class="pb-0"
      >
        <v-alert
          text
          type="error"
          tile
          class="mb-0"
        >
          Не удалось скачать файл.
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-col cols="6">
          <v-btn
            block
            large
            tile
            text
            @click="onCloseDialog"
          >
            Отмена
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            block
            large
            tile
            text
            class="primary"
            :loading="loading"
            @click="onDownload"
          >
            Скачать
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>