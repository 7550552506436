<script>
import {
  downloadDepartmentFile,
  getDepartments, getManagersOfDepartment, sign,
} from "@/services/api/requests";
import {mapGetters} from "vuex";
import {mdiArrowRight, mdiDraw} from "@mdi/js";
import Status from "@/components/Status.vue";
import CardUser from "@/components/CardUser.vue";
import {getMonthName, statusKeys} from "@/services/mappers";
import EfficiencyCoefficient from "@/components/EfficiencyCoefficient.vue";
import DocumentViewer from "@/components/DocumentViewer.vue";
import DialogDocumentViewer from "@/components/DialogDocumentViewer.vue";
import LabelDeadline from "@/components/LabelDeadline.vue";

export default {
  name: "Department",
  components: {LabelDeadline, DialogDocumentViewer, DocumentViewer, EfficiencyCoefficient, CardUser, Status},
  data: function () {
    return {
      statusKeys,
      mdiArrowRight,
      mdiDraw,
      year: null,
      month: null,
      signing: false,
      loadingDepartments: false,
      loadingDepartment: false,
      downloadingDepartmentFile: false,
      departments: [],
      selectedDepartment: null,
      managers: [],
    }
  },
  computed: {
    ...mapGetters("User", {
      isHeadOfDepartment: 'isHeadOfDepartment',
      userDepartment: 'getDepartment',
      userId: 'getId',
      currentUser: 'getUser',
    }),
    isUserAllowed() {
      return this.isHeadOfDepartment
    },
    showSplitScreen() {
      return this.selectedDepartment.status === statusKeys.signed && !this.$vuetify.breakpoint.smAndDown;
    },
    documentsForDocViewer() {
      return [{
        month: this.month,
        year: this.year,
        departmentId: this.selectedDepartment?.id,
        type: 'pdf',
        name: `${getMonthName(this.month)}_${this.year}`,
        id: this.selectedDepartment?.id,
      }]
    },
    overflowStyle() {
      return this.$vuetify.breakpoint.smAndDown
        ? null
        : {
          overflowY: "auto",
          height:
            (this.contentHeight < this.minHeight
              ? this.minHeight
              : this.contentHeight) + "px"
        };
    },
    deadline() {
      return this.selectedDepartment?.deadline
    },
  },
  async created() {
    this.year = this.$route.query.year
    this.month = this.$route.query.month
    if (!this.year || !this.month || !this.isUserAllowed) {
      await this.$router.push('/')
    }
    this.loadingDepartments = true
    await this.fetchDepartments()
    this.loadingDepartments = false
    if (this.selectedDepartment) {
      await this.fetchManagersOfDepartment(this.selectedDepartment.id)
    } else {
      await this.$router.push('/')
    }
  },
  methods: {
    getMonthName,
    async fetchDepartments() {
      try {
        const response = await getDepartments(this.year, this.month)
        this.departments = response.departments
        if (!this.selectedDepartment) {
          this.selectedDepartment = this.departments[0]
        } else {
          this.selectedDepartment = this.departments.find((department) => department.id === this.selectedDepartment.id)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async fetchManagersOfDepartment(selectedDepartment) {
      this.loadingDepartment = true
      const res = await getManagersOfDepartment(this.year, this.month, selectedDepartment)
      this.managers = res.map((manager) => {
        return {
          ...manager,
          editable: this.editableFormular(manager.status, manager.manager)
        }
      })
      this.loadingDepartment = false
    },
    getLocalDate(date) {
      return new Date(date).toLocaleDateString()
    },
    goToFormular(formularId) {
      this.$router.push({name:'Formular', params: {formularId}})
    },
    editableFormular(status, manager) {
      const isSameUser = this.userId === manager.id
      const isEditableStatus = [statusKeys.new, statusKeys.declined, statusKeys.in_progress].includes(status)
      return isSameUser && isEditableStatus
    },
    async signDepartment() {
      this.signing = true
      try {
        await sign(this.year, this.month, this.selectedDepartment.id)
        this.signing = false
        await this.fetchDepartments()
      } catch (e) {
        this.signing = false
      }
    },
    async downloadDepartmentFile() {
      try {
        this.downloadingDepartmentFile = true
        const link = await downloadDepartmentFile(this.year, this.month, this.selectedDepartment.id)
        link.download = `Премии_формуляр_${this.formularMonth}_${this.formularYear}.pdf`;
        document.body.appendChild(link);
        link.click();
        setTimeout(()=> this.downloadingDepartmentFile = false, 100)
      } catch (e) {
        this.downloadingDepartmentFile = false
      }
    },
    // gets the string between the first quotes from the end of the string
    getDepartmentShortName(departmentName) {
      const lastQuoteIndex = departmentName.lastIndexOf('"')
      // get index of the second quote from the end
      const secondQuoteIndex = departmentName.lastIndexOf('"', lastQuoteIndex - 1)
      return departmentName.substring(secondQuoteIndex + 1, lastQuoteIndex)
    },
    async switchDepartment(selectedId) {
      this.selectedDepartment = this.departments.find(({id}) => id.toString() === selectedId)
      await this.fetchManagersOfDepartment(this.selectedDepartment.id)
    },
  }
}
</script>

<template>
  <v-container fluid>
    <div
      v-if="loadingDepartments"
      class="text-center d-flex flex-column justify-center align-center my-10"
      style="width: 100%"
    >
      <v-progress-circular
        indeterminate
        height="60"
        color="primary"
      />
      <span class="mt-5">Загружаются статусы по обособленным подразделениям</span>
    </div>
    <v-row v-else>
      <v-col
        :cols="showSplitScreen ? 6 : 12"
        :style="overflowStyle"
      >
        <v-tabs
          v-if="departments.length > 1"
          show-arrows
          @change="switchDepartment"
        >
          <v-tab
            v-for="department in departments"
            :key="department.id"
            :href="`#${department.id}`"
            :class="{'text--primary': department.status === statusKeys.open}"
          >
            {{ getDepartmentShortName(department.department_name) }}
          </v-tab>
        </v-tabs>
        <template v-if="!!selectedDepartment">
          <h3 class="mt-10">
            {{ getMonthName(month) }} {{ year }}
            <LabelDeadline
              v-if="deadline"
              :deadline="deadline"
            />
          </h3>
          <h3 class="">
            {{ selectedDepartment.department_name }}
          </h3>
          <p class="mt-5">
            Руководитель подразделения:
            <CardUser :user="currentUser" />
          </p>
          <p>
            Общий групповой показатель KPI:
            <EfficiencyCoefficient
              :department="selectedDepartment"
              :month="month"
              :year="year"
              @update="fetchDepartments"
            />
          </p>
          <v-alert
            v-if="selectedDepartment.status === statusKeys.open"
            class="my-5"
            outlined
            rounded
            flat
          >
            <span class="mr-2">Статус по подразделению:</span>
            <Status
              :status="selectedDepartment.status"
              small
              class="flex-grow-0"
            />
            <p class="mt-3">
              Формуляр по подразделению будет готов к подписанию, когда все менеджеры заполнят формуляр (все формуляры ниже получат статус "Принято")
            </p>
          </v-alert>
          <v-alert
            v-if="selectedDepartment.status === statusKeys.closed"
            class="my-5"
            outlined
            rounded
            flat
            type="info"
          >
            <span class="mr-2">Статус обособленного подразделения:</span>
            <Status
              small
              :status="selectedDepartment.status"
              class="flex-grow-0"
            />
            <p class="mt-3">
              Формуляр по обособленному подразделению готов к подписанию
            </p>
            <v-btn
              color="info"
              :loading="signing"
              small
              depressed
              @click="signDepartment"
            >
              Подписать
              <v-icon>
                {{ mdiDraw }}
              </v-icon>
            </v-btn>
          </v-alert>
          <v-alert
            v-if="selectedDepartment.status === statusKeys.signed"
            class="my-5"
            outlined
            rounded
            flat
            type="primary"
          >
            <span class="mr-2">Статус обособленного подразделения:</span>
            <Status
              small
              :status="selectedDepartment.status"
              class="flex-grow-0"
            />
            <p class="mt-3">
              Формуляр по обособленному подразделению подписан
            </p>
            <div
              class="d-flex flex-wrap"
              style="gap: 8px"
            >
              <DialogDocumentViewer
                v-if="!showSplitScreen"
                :documents="documentsForDocViewer"
              />
              <v-btn
                color="primary"
                outlined
                small
                :loading="downloadingDepartmentFile"
                @click="downloadDepartmentFile"
              >
                Скачать
              </v-btn>
            </div>
          </v-alert>
          <div
            v-if="loadingDepartment"
            class="text-center d-flex flex-column justify-center align-center my-10"
            style="width: 100%"
          >
            <v-progress-circular
              indeterminate
              height="60"
              color="primary"
            />
            <span class="mt-5">
              Загружаются статусы по менеджерам обособленного подразделения
              <strong>{{ selectedDepartment.department_name }}</strong>
            </span>
          </div>
          <v-simple-table v-else>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Менеджер
                  </th>
                  <th class="text-left">
                    Статус
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr v-if="!managers.length">
                  <td
                    colspan="3"
                    class="text-center"
                  >
                    Не найдено ни одного формуляра
                  </td>
                </tr><tr
                  v-for="{editable, manager, id, status: statusManager} in managers"
                  :key="id"
                  class="table-class"
                >
                  <td style="padding-bottom: 10px !important; padding-top: 10px !important;">
                    <CardUser :user="manager" />
                  </td>
                  <td style="padding-bottom: 10px !important; padding-top: 10px !important;">
                    <Status
                      small
                      :status="statusManager"
                    />
                  </td>
                  <td style="padding-bottom: 10px !important; padding-top: 10px !important;">
                    <v-btn
                      small
                      depressed
                      color="primary"
                      :text="!editable"
                      @click="goToFormular(id)"
                    >
                      <span v-if="editable">редактировать</span>
                      <span v-else>к формуляру</span>
                      <v-icon
                        small
                        class="mt-n1 ml-2"
                      >
                        {{ mdiArrowRight }}
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </v-col>
      <v-col
        v-if="showSplitScreen"
        cols="6"
      >
        <DocumentViewer
          :min-height="650"
          :documents="documentsForDocViewer"
        />
      </v-col>
    </v-row>
  </v-container>
</template>