<script>
import DialogDownload from "@/components/DialogDownload.vue";
import {mapGetters, mapState} from "vuex";
import {mdiClose, mdiDownloadOutline, mdiMinus, mdiPlus, mdiRotateLeft, mdiRotateRight} from "@mdi/js";
import {getInlineDocument} from "@/services/api/requests";

export default {
  name: "DocumentViewer",
  components: {DialogDownload},
  props: {
    minHeight: {
      type: Number,
      required: true,
    },
    dialog: {
      type: Boolean,
    },
    documents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedDocument: null,
      imageRotation: 0,
      imageScale: 1,
      preview: "",
      loading: false,
      downloadPercentage: 0,
      icons: {
        close: mdiClose,
        plus: mdiPlus,
        download: mdiDownloadOutline,
        minus: mdiMinus,
        rotateRight: mdiRotateRight,
        rotateLeft: mdiRotateLeft
      },
    };
  },
  computed: {
    ...mapState("App", {
      height: "height"
    }),
    ...mapGetters("App", {
      contentHeight: "contentHeight"
    }),
    percentage() {
      return this.downloadPercentage?.toFixed(0) || "0";
    },
    docHeight() {
      if (this.dialog) return this.height - 81;
      else
        return (
          (this.contentHeight < this.minHeight
            ? this.minHeight
            : this.contentHeight) - 89
        );
    },
    isImage() {
      return this.selectedDocument.type === "image";
    },
    isPDF() {
      return this.selectedDocument.type === "pdf";
    },
    imageTranslate() {
      switch (Math.abs(this.imageRotation % 360)) {
        case 270:
          return "0%, 0%";
        case 180:
          return "-50%, -50%";
        case 90:
          return "0%, 0%";
        default:
          return "-50%, -50%";
      }
    },
    imageStyle() {
      const style = {
        transform: `rotate(${this.imageRotation}deg) translate(${this.imageTranslate})`,
        width: `${100 * this.imageScale}%`,
        height: `${100 * this.imageScale}%`
      };
      const offset = `${50 * (this.imageScale < 1 ? 1 : this.imageScale)}%`;
      if (Math.abs(this.imageRotation % 360) === 0) {
        style.top = offset;
        style.left = offset;
      }
      return style;
    }
  },
  created() {
    this.selectedDocument = this.documents[0];
    this.onPreview();
  },
  methods: {
    setDownloadPercentage({total, loaded}) {
      if (!!loaded && !!total) {
        const res = (100 * loaded) / total;
        this.downloadPercentage = res === 100 || res === 0 ? 0 : res;
      } else this.downloadPercentage = 0;
    },
    async onPreview() {
      this.loading = true;
      let response = await getInlineDocument(this.selectedDocument, this.setDownloadPercentage);
      response = response.split(";");
      const contentType = response[0].substr(5);
      const base64Data = response[1].substr(7);
      const file = this.base64toFile(
        base64Data,
        this.selectedDocument.name,
        contentType
      );
      this.preview = URL.createObjectURL(file);
      this.loading = false;
    },
    base64toFile(base64Data, name, contentType) {
      contentType = contentType || "";
      const sliceSize = 1024;
      const byteCharacters = atob(base64Data);
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);

      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new File(byteArrays, name, { type: contentType });
    },
    onScaleMinus() {
      this.imageScale -= 0.1;
    },
    onScalePlus() {
      this.imageScale += 0.1;
    },
    onRotateRight() {
      this.imageRotation += 90;
    },
    onRotateLeft() {
      this.imageRotation -= 90;
    }
  }
}
</script>

<template>
  <v-container
    fluid
    fullscreen
    class="ma-0 pa-0 container_preview"
  >
    <DialogDownload
      :id="selectedDocument.id"
      :name="selectedDocument.name"
    >
      <template #activator="{on}">
        <v-select
          ref="select"
          v-model="selectedDocument"
          :disabled="loading"
          :loading="loading"
          :items="documents"
          item-text="name"
          class="mb-3"
          outlined
          dense
          return-object
          persistent-hint
          label="Выберите документ"
          :hide-details="!(loading && percentage !== '0')"
          :hint="`Загрузка файла ${percentage}%`"
          :prepend-icon="dialog && false ? icons.download : null"
          :append-outer-icon="dialog ? icons.close : null"
          @click:prepend="on.click"
          @click:append-outer="$emit('close')"
          @change="onPreview"
        />
      </template>
    </DialogDownload>
    <iframe
      v-if="!loading && isPDF"
      :src="preview"
      seamless="seamless"
      width="100%"
      :name="selectedDocument.name"
      :height="docHeight"
      frameborder="0"
      border="0"
      style="display: block"
    />
    <div
      v-if="!loading && isImage"
      ref="image_wrapper"
      class="image_wrapper"
      :style="`height: ${docHeight}px`"
    >
      <img
        :src="preview"
        class="image_preview"
        :alt="selectedDocument.name || 'Image'"
        :style="imageStyle"
      >
    </div>

    <v-btn-toggle
      v-if="isImage"
      class="image_toolbar"
    >
      <v-btn
        small
        :disabled="imageScale <= 0.3"
        @click="onScaleMinus"
      >
        <v-icon>{{ icons.minus }}</v-icon>
      </v-btn>
      <v-btn
        small
        :disabled="imageScale >= 3"
        @click="onScalePlus"
      >
        <v-icon>{{ icons.plus }}</v-icon>
      </v-btn>
      <v-btn
        small
        @click="onRotateLeft"
      >
        <v-icon>{{ icons.rotateLeft }}</v-icon>
      </v-btn>
      <v-btn
        small
        @click="onRotateRight"
      >
        <v-icon>{{ icons.rotateRight }}</v-icon>
      </v-btn>
    </v-btn-toggle>
    <v-skeleton-loader
      v-if="loading"
      type="image"
      class="loader"
      :height="docHeight - (percentage !== '0' ? 26 : 0)"
      :loading="loading"
    />
  </v-container>
</template>

<style scoped lang="scss">
.loader::v-deep {
  .v-skeleton-loader__image {
    height: 100% !important;
  }
}
.image_toolbar::v-deep {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  .v-btn--active::before {
    opacity: 0 !important;
  }
}
.image_preview {
  transition: transform 0.3s ease;
  position: absolute;
  object-fit: contain;
}
.image_wrapper {
  width: 100%;
  overflow: scroll;
  position: relative;
}
.container_preview {
  padding-top: 10px !important;
  position: relative;
}
</style>
